import Form from '../../layouts/Form/Form';
import Heading from '../../layouts/Heading/Heading';
import React, { useLayoutEffect } from 'react';

const GetInTouch = () => {
    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });
    return (
        <>
            <Heading heading="Get In Touch" alignClass="SideHeading" />
            <Form />
        </>
    )
}

export default GetInTouch