import './Shipping.css';
import React, { useLayoutEffect } from 'react';

const Shipping = () => {
    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });
    return (
        <><h1 className='NameH1'>Shipping and Delivery</h1>
        <div className='P1Img1Div'>
                <p className='P1P'>
<p>This shipping policy explains how Astronumerals operates its shipping procedures and how we strive to meet your expectations with every order. Whether you’re a first-time buyer or a returning customer, we want to ensure that your experience with us is smooth and satisfactory, right from placing your order to the moment it arrives at your doorstep. This policy has been created with the help of the <a href="https://www.websitepolicies.com/shipping-policy-generator">shipping policy generator</a>.</p>
<p>Please read this shipping policy together with our <a href="https://astronumerals.com/tnc">terms and conditions</a> to familiarize yourself with the rest of our general guidelines.</p>
<div class="toc"><br/><br/><h3>Table of contents</h3><br/><ol class="toc"><li><a href="#shipping-and-delivery-options">Shipping and Delivery Options</a></li><li><a href="#delayed-orders">Delayed Orders</a></li><li><a href="#returns-and-exchanges">Returns and Exchanges</a></li><li><a href="#contact-information">Contact Information</a></li></ol></div>
<br/><br/><h2 id="shipping-and-delivery-options">Shipping and Delivery Options</h2><br/>
<p>We offer a variety of shipping options to suit the needs of our customers.</p>
<br/><br/><h3>Free Shipping</h3><br/>
<p>As part of our commitment to an exceptional shopping experience, we are pleased to offer free shipping.</p>
<br/><br/><h3>Shipping Methods</h3><br/>
<p>We offer a variety of shipping options to suit the needs of our customers:</p>
<ul><li>Online astrology consultancy service</li></ul>
<br/><br/><h2 id="delayed-orders">Delayed Orders</h2><br/>
<p>Unexpected delays can occur due to various reasons such as logistic challenges, inclement weather, high demand, or carrier issues. We are committed to handling these situations with transparency and efficiency. In the event of a delay, our priority is to keep you informed. We will promptly notify you with updates on the status of your order and the expected new delivery time. Our goal is to provide clear and accurate information so you can plan accordingly.</p>
<p>Understanding the inconvenience caused by delays, we offer options to maintain your satisfaction. If your order is significantly delayed, you will have the choice to continue with the order, modify it, or cancel it for a full refund. Our customer service team is always available to assist with any changes to your order.</p>
<br/><br/><h2 id="returns-and-exchanges">Returns and Exchanges</h2><br/>
<p>If you have any questions about refunds, returns or exchanges, please review our <a href="https://astronumerals.com/refund">refund policy</a>.</p>
<br/><br/><h2 id="contact-information">Contact Information</h2><br/>
<p>If you have any questions or concerns regarding our shipping policy, we encourage you to contact us using the details below:</p>
<ul><li><a href="https://astronumerals.com/getintouch">https://astronumerals.com/getintouch</a></li><li><a href="mailto:priasonawat@gmail.com">priasonawat@gmail.com</a></li></ul>
<p>This document was last updated on October 3, 2024</p>
</p>
            </div>
        </>
    )
}

export default Shipping