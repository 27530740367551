import './Policy.css';
import React, { useLayoutEffect } from 'react';

const Policy = () => {
    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });
    return (
        <><h1 className='NameH1'>Privacy Policy</h1>
        <div className='P1Img1Div'>
                <p className='P1P'>At Astro Numerals, safeguarding your privacy and ensuring the security of your personal information is our top priority. This Privacy Policy details how we collect, utilize, disclose, and protect your data when you engage with our numerology services through our website.
                    <br /> <br />
                    Information Collection:
        We gather diverse information during your interactions with our website and services, including:
                    <br /> <br />
                    Personal Details: When you create an account, make purchases, or subscribe to our updates, we may collect your name, email address, phone number, and billing address.
                    <br /> <br /> 
                    Payment Data: For transactions, we gather payment information like credit card details or other payment account specifics.<br /> <br /> 
                    Usage Insights: We automatically collect data about your website interactions, such as IP address, browser type, device identifiers, visited pages, and other usage patterns.<br /> <br /> 
                    Cookies and Tracking: We utilize cookies and similar technologies to enhance your browsing experience and analyze website traffic. Note that disabling cookies may impact certain features.<br /> <br /> Utilization of Information:
        We employ the data we gather for several purposes, including:
        <br /> <br /> 
        Enhancing and refining our products and services
        Processing transactions and fulfilling orders efficiently<br /> <br /> 
        Keeping you informed about your account, orders, and exclusive offers<br /> <br /> 
        Customizing your experience and delivering pertinent content<br /> <br /> 
        Analyzing website usage and trends to enhance our services<br /> <br /> 
        Safeguarding against fraud and ensuring the security of our website and users
        <br /> <br /> Information Sharing and Disclosure:
        We may share your information with third parties for various reasons, including:
        <br /> <br /> 
        Service Providers: Third-party service providers aid us in website operations, payment processing, order fulfillment, and service delivery.<br /> <br /> 
        Legal Compliance: We may disclose information to comply with applicable laws, regulations, legal processes, or government requests.<br /> <br /> 
        Business Transfers: In cases of mergers, acquisitions, or business transfers, your data may be transferred as part of the transaction.<br /> <br /> 
        Consent: We may share data with your consent or as described during data collection.<br /> <br /> 
        Data Security:
        While we implement reasonable measures to protect your data, absolute security cannot be guaranteed due to the inherent nature of internet transmission and electronic storage.
        <br /> <br /> 
        Your Choices and Rights:
        You have rights concerning your data, including access, correction, or deletion. You can also opt out of certain communications or data processing activities. Contact us using the provided information to exercise your rights.
        <br /> <br />
        Privacy Policy Updates:
        We may update this Privacy Policy to reflect changes in practices or laws. We encourage you to review it periodically for any updates or modifications.
        <br /> <br /> 
        Contact Us:
        For questions, concerns, or feedback regarding our Privacy Policy or practices, reach out to us at +91 62913 07553.
        <br /> <br /> 
        By using our website or services, you agree to the collection, use, and disclosure of your information as outlined in this Privacy Policy.</p>
            </div>
        </>
    )
}

export default Policy