import './Home.css';
import React, { useLayoutEffect } from 'react';
import home1 from "../../images/home_1.png";
import home2 from "../../images/astro_learn_about _destiny.png";
import home3 from "../../images/astro-02.png";
import home4 from "../../images/astro-03.png";
import home5 from "../../images/astro-04.png";
import Button from '../../layouts/Button/Button';

const Home = () => {
    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });
    return (
        <div className='ContainerDiv'>
            <h1 className='NameH1'>Pria Sonawat</h1>
            <div className='P1Img1Div'>
                <p className='P1P'>Welcome to the captivating world of Pria Sonawat, a seasoned
                    numerologist whose expertise transcends the boundaries of mere numbers,
                    unlocking profound insights into the intricate tapestry of life. With a
                    yearning to decipher the cosmic code that governs our existence, I have
                    emerged as a luminary in the realm of numerology.
                    <br /> <br /> 
                    Embark on a transformative journey as you explore the depths of
                    numerology through my keen insights and intuitive mastery. Unravel the
                    mysteries of your life’s path, personality, and potential.
                    <br /> <br /> 
                    Through a synthesis of ancient wisdom and modern understanding, we
                    offer a unique perspective on the significance of numbers in our lives.
                    Whether you seek clarity in relationships, career choices, or personal
                    growth, numerological guidance provides a roadmap for navigating the
                    intricacies of your journey.
                    <br /> <br /> 
                    Join me on this enchanting exploration of self-discovery, guided by the
                    wisdom and expertise, as we delve into the profound world of numerology
                    and uncover the hidden patterns that connect us to the universe.</p>
                <img className="Home1Img" src={home1} alt="Astrologer"></img>
            </div>
            <div className='offerDiv'>
                <h1 className="fullReadingsH1">For full readings & predictions
                    At INR <s>999/-</s> 399/- only. Click Below</h1>
                <Button url="/offers" title="GET NOW" cName='ButtonDiv' />
            </div>
            <h1 className="ExperienceH1">5 YEARS OF EXPERIENCE</h1>
            <div className='P2Img2Div'>
                <div>
                    <p className='P2P'><span className='P2a'>Learn more <br />about your destiny</span>
                        <br /> <br />
                        {/* <span className='P2b'>ASK ONE QUESTION FOR FREE!</span> */}
                        <br />
                    </p>
                    <Button url="/services" title="GET STARTED" cName='StartButtonDiv' />
                </div>
                <img className="Home2Img" src={home2} alt="Destiny numbers"></img>
            </div>
            <div className='catMainDiv'>
                <div className="cat">
                    <img className="catImg" src={home3} alt="Privacy"></img>
                    <h1>
                        Privacy
                    </h1>
                    <p>Information obtained during<br />
                        the consultation is private</p>
                </div>
                <div className="cat">
                    <img className="catImg" src={home4} alt="Result guarantee"></img>
                    <h1>
                        Result guarantee
                    </h1>
                    <p>We provide a solution oriented<br />
                        consultation.</p>
                </div>
                <div className="cat">
                    <img className="catImg" src={home5} alt="Competence"></img>
                    <h1>
                        Competence
                    </h1>
                    <p>We only take on issues<br />
                        that we can solve
                    </p>
                </div>

            </div>
            
        </div >

    )
}

export default Home