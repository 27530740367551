import React, { useLayoutEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { ServiceData } from "./ServiceData";
import "./ServiceDetails.css";
import Heading from "../../layouts/Heading/Heading";
const ServicesDetails = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });
  var { id } = useParams();
  return (
    <div>
      {ServiceData.map((item, index) => {
        if (item.url === id) {
          return (
            <div key={index} className="c1">
              <Heading heading={item.title} alignClass="CenterHeading" />
              <p className="introText" >{item.intro}</p>
              <br></br>
              <div className="detailsImgContainer">
                <img
                  src={require(`../../images/serviceDetails/${item.url}.jpeg`)}
                  className="details1"
                  alt={item.url}
                ></img>
              </div>
              {Object.keys(item.subcat).map((key, index) => {
                return (
                  index === 0 && (
                    <div className="firstSubSection" key={key}>
                      <div>
                        <h1>{key}</h1>
                        <p>
                          {item.subcat[key].split(/\n/).map((line, index) => (
                            <React.Fragment key={line}>
                              {line}
                              <br />
                              <br />
                            </React.Fragment>
                          ))}
                        </p>
                      </div>
                      <div className="circle1">
                        <img
                          src={require(`../../images/serviceDetails/${item.src}.png`)}
                          alt={item.src}
                        ></img>
                      </div>
                    </div>
                  )
                );
              })}
              <div className="allSubSection">
                {Object.keys(item.subcat).map((key, index) => {
                  return (
                    index !== 0 &&
                    index !== 4 && (
                      <div key={key} className="allSubSection_Card">
                        <div className="circle2">
                          <img
                            src={require(`../../images/serviceDetails/details_icon_${index}.png`)}
                            alt="detailsIcon"
                          ></img>
                        </div>
                        <h1>{key}</h1>
                        <p>
                          {item.subcat[key].split(/\n/).map((line, index) => (
                            <React.Fragment key={line}>
                              {line}
                              <br />
                              <br />
                            </React.Fragment>
                          ))}
                        </p>
                        <div>
                          <img
                            src={require(`../../images/serviceDetails/line_details.png`)}
                            alt="line_details"
                          ></img>
                        </div>
                      </div>
                    )
                  );
                })}
              </div>
              {Object.keys(item.subcat).map((key, index) => {
                return (
                  index === 4 && (
                    <div className="thirdSubSection" key={key}>
                      <div className="circle3">
                        <img
                          src={require(`../../images/serviceDetails/conclusion.png`)}
                          alt="conclusion"
                        ></img>
                      </div>
                      <div>
                        <h1>{key}</h1>
                        <p>
                          {item.subcat[key].split(/\n/).map((line, index) => (
                            <React.Fragment key={line}>
                              {line}
                              <br />
                              <br />
                            </React.Fragment>
                          ))}
                        </p>
                      </div>
                    </div>
                  )
                );
              })}
              <div className="btnPaymentContainer">
              <Link to="/offers">   
            <button className="buttonPayment">
        <p>Book Your 20-Minute Session Now at  <s>₹1999/-</s> ₹1099/-</p>
      </button>
      </Link>
      </div>
              <h1 className="queryHeader">Got Queries? We’ve got your answers.</h1>
              <div className="queryContainer">
                <div className="queryCard">
                  <h5>What is the technique used for the analysis?</h5>
                  <p>
                    We use a combination of astrological knowledge and
                    numerological aspects based on birth dates.
                  </p>
                </div>
                <div className="queryCard">
                  <h5>Is the process confidential?</h5>
                  <p>
                  Absolutely! Your details are completely safe and the analysis is done maintaining strict confidentiality.
                  </p>
                </div>
                </div>
                <div className="queryContainer">
                <div className="queryCard">
                  <h5>How to book a session?</h5>
                  <p>
                  Simply click on the “Get In Touch” button on our page. You’ll be directed to fill up a form. Our team will get back to you post submission.
                  </p>
                </div>

                {/* <div className="queryCard">
                  <h5>How to book a session?</h5>
                  <p>
                  Simply click on the “Get In Touch” button on our page. You’ll be directed to fill up a form. Our team will get back to you post submission.
                  </p>
                </div> */}
              </div>
            </div>
          );
        }
      })}
    </div>
  );
};

export default ServicesDetails;
