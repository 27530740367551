export const MenuItems = [
    {
        title: "Home",
        url: "/",
        cName: "MenuL"
    },
    {
        title: "Services",
        url: "/services",
        cName: "MenuL"
    },
    {
        title: "Get In Touch",
        url: "/getintouch",
        cName: "MenuL"
    }
]