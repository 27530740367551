export const ServiceItems = [
  {
    title: "NAME CORRECTION",
    url: "/services/namecorrection",
    cName: "ServiceL",
    src:"nameCorrection",
  },
  {
    title: "BUSINESS OR COMPANY NAME CORRECTION",
    url: "/services/businessnamecorrection",
    cName: "ServiceL",
    src:"businessNameCorrection",
  },
  {
    title: "MARRIAGE COMPATIBILITY",
    url: "/services/marriagecompatibility",
    cName: "ServiceL",
    src:"marriageCompatibility",
  },
  {
    title: "BABY NAMING",
    url: "/services/babynaming",
    cName: "ServiceL",
    src:"babyNaming",
  },
  {
    title: "CAREER NUMEROLOGY",
    url: "/services/careernumerology",
    cName: "ServiceL",
    src:"careerNumerology",
  },
  {
    title: "HEALTH NUMEROLOGY",
    url: "/services/healthnumerology",
    cName: "ServiceL",
    src:"healthNumerology",
  }
];
