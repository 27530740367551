export const OffersData = [
  {
    "Free Trial": {
      Pointers: [
        "Give it a try with our experts",
        "Answer for One Question",
        "Response in 5 days",
        "Know your lucky numbers",
        "Pay ₹0"
      ],
      Button: "Book Trial"
    },
    "Full Readings": {
      Pointers: [
        "Get Full Readings And Predictions",
        "Answers for Five Questions",
        "Response in 3 days",
        "Get detailed PDF file ",
        "Pay ₹399 instead of ₹999 today"
      ],
      Button: "Pay ₹399"
    },
    "Live Session": {
      Pointers: [
        "Get a complete focussed session",
        "Answers for Multiple Questions",
        "Response in 1 day",
        "20 minutes Interactive session",
        "Pay ₹1099 instead of ₹1999 today"
      ],
      Button: "Pay ₹1099"
    }
  }
];
