import './PaymentSuccess.css';
import React, { useLayoutEffect } from 'react';

const PaymentSuccess = () => {
    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });
    return (
        <><h1 className='NameH1'>Booking confirmed</h1>
        <div className='P1Img1Div'>
                <p className='P1P'>Please check your email or Whatsapp for further communication.</p>
            </div>
        </>
    )
}

export default PaymentSuccess