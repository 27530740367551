import './Services.css';
import React, { useLayoutEffect } from 'react';
import { ServiceItems } from './Serviceitems';
import Form from '../../layouts/Form/Form';
import Heading from '../../layouts/Heading/Heading';
import { Link } from 'react-router-dom';
const Services = () => {
    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });
    return (
        <div className="ServiceContainer">
            <h1 className='ServiceH1'>Services</h1>
            <ul className="ServicesUl">
                {ServiceItems.map((item, index) => {
                    return (<li key={index} className={item.cName} component={Link} to={item.url}>
                        <Link className="LinkContainer" style={{ color: 'inherit', textDecoration: 'inherit'}}to={item.url}>
                        <div className='circle'><img src={require(`../../images/serviceDetails/${item.src}.png`)} alt={item.src}></img></div>
                        {item.title}
                        </Link>
                    </li>)
                })}
            </ul >
            <div className="btnPaymentContainer">
            <Link to="/offers">
            <button className="buttonPayment">
        <p>Book Your 20-Minute Session Now at  <s>₹1999/-</s> ₹1099/-</p>
      </button>
      </Link>
      </div>
            <Heading heading="Contact Us Now!" alignClass="CenterHeading" />
            <Form />
        </div>
    )
}

export default Services