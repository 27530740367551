import "./Header.css";
import React, { useState } from 'react';
import logo from "../../images/logo.png";
import { MenuItems } from "./MenuItems";
import { NavLink } from "react-router-dom";

const Header = () => {
    const [isMenuOpened, setIsMenuOpened] = useState(false);
    function handleMenuOpen() {
        setIsMenuOpened((isMenuOpened) => !isMenuOpened)
    }

    return (
        <nav className="ContainerNav">
            <div className="LogoTitleHamDiv">
                <img className="LogoImg" src={logo} alt="astronumerals logo"></img>
                <div className="TitleHamDiv">
                    <h1 className="TitleH1">Astronumerals</h1>
                    <div className="ToggleDiv" onClick={handleMenuOpen}>
                        <i className={isMenuOpened ? "fa-regular fa-circle-xmark fa-lg" : "fa-solid fa-bars fa-lg"}></i>
                    </div>
                </div>

            </div>

            <ul className={isMenuOpened ? "MenusUl" : "InactiveMenusUl"}>
                {MenuItems.map((item, index) => {
                    return (<li key={index} className={`${item.cName}`} onClick={handleMenuOpen}><NavLink to={item.url} className={({ isActive }) => (isActive ? 'active' : '.inactive')}>{item.title}</NavLink></li>)
                })}
            </ul >

        </nav >

    )
}

export default Header