import React, { useState } from 'react'
import './Form.css'
import { ToastContainer, toast } from 'react-toastify';
const Form = () => {
    const [inputs, setInputs] = useState({});

    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputs(values => ({ ...values, [name]: value }))
    }

    const handleSubmit = async(event) => {
        event.preventDefault();
        console.log(inputs);
        const response = await fetch("https://astronumerals-backend.onrender.com/sendmail", {
            method: "POST",
            body: JSON.stringify(
              {sub:"New form data at Astronumerals",body:`<p>USERNAME: ${inputs.username}</p><p>CONTACT: ${inputs.contact}</p><p>EMAIL: ${inputs.email}</p><p>DOB: ${inputs.dob}</p><p>MESSAGE: ${inputs.message}</p>`}),
            headers: {
              "Content-Type": "application/json",
            },
          });
          const sentMail = await response.json();
          if(sentMail.success){
            // window.scrollTo(0, 0);
            setInputs("");
            toast.success('Your data has been submitted successfully!', {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "colored"
                });
          }
          
        // var url = "https://wa.me/916291307553?text="
        //     + "Name: " + inputs.username + "%0a"
        //     + "Phone: " + inputs.contact + "%0a"
        //     + "Email: " + inputs.email + "%0a"
        //     + "Date of birth: " + inputs.dob + "%0a"
        //     + "Message: " + inputs.message;

        // window.open(url, '_blank').focus();
    }
    return (
        <>
        <ToastContainer position="top-center"
autoClose={5000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick={false}
rtl={false}
pauseOnFocusLoss={false}
draggable
pauseOnHover={false}
theme="colored"/>
        <form className="Form" onSubmit={handleSubmit}>
            <div className='FormFields'>
                <div className="Column1">
                    <div className="row">
                        <div className="col-25">
                            <label htmlFor="username">NAME</label>
                        </div>
                        <div className="col-75">
                            <input
                                type="text"
                                name="username"
                                value={inputs.username || ""}
                                placeholder="Your name"
                                onChange={handleChange}
                                required
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-25">
                            <label htmlFor="contact">CONTACT NUMBER</label>
                        </div>
                        <div className="col-75">
                            <input
                                type="tel"
                                name="contact"
                                value={inputs.contact || ""}
                                placeholder="Your contact number"
                                onChange={handleChange}
                                required
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-25">
                            <label htmlFor="email">EMAIL</label>
                        </div>
                        <div className="col-75">
                            <input
                                type="email"
                                name="email"
                                value={inputs.email || ""}
                                onChange={handleChange}
                                placeholder="Your email address"
                                required
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-25">
                            <label htmlFor="dob">DATE OF BIRTH</label>
                        </div>
                        <div className="col-75">
                            <input
                                type="date"
                                name="dob"
                                placeholder='Your D.O.B.'
                                value={inputs.dob || ""}
                                onChange={handleChange}
                                required
                            />
                        </div>
                    </div>
                </div>
                <div className="Column2 row">
                    <div className="col-25">
                        <label htmlFor="message">ASK ONE QUESTION FOR FREE!</label>
                    </div>
                    <div className="textAreaDiv">
                        <textarea
                            cols='60' rows='8' wrap="hard"
                            name="message"
                            value={inputs.message || ""}
                            onChange={handleChange}
                            placeholder='Your question'
                            required></textarea>
                    </div>
                </div>
            </div>
            <div className='ButtonDiv'>
                <input className="Button" type="submit" value="BOOK TRIAL" />
            </div>
        </form>
        </>
    )
}

export default Form