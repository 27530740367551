import React from 'react'
import { Link } from 'react-router-dom'
import './Button.css'

const Button = ({ url, title, cName }) => {
    return (
        <div className={cName}>
            <Link to={url}>
                <button className="Button">{title}</button>
            </Link>
        </div>
    )
}

export default Button